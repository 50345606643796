body {
  margin: 0;
  padding: 50px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.sign-out {
  float: right;
}
.sign-out:hover, button:hover {
  cursor: pointer;
}

.summary {
  border: 1px solid #000;
  padding: 20px;
  float: left;
  width: 45%;
  margin-top:120px;
  margin-left:9%;
}

.bottom {
  clear: both;
  float: left;
  width: 45%;
}